<template>
  <div class="row">
    <div class="col">
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('profile.userprofile')" active>
          <div class="card">
            <div class="card-body">
              <!-- Email -->
              <div class="input-group mb-3">
                <div class="input-group-prepend input-group-text">
                  <i class="fa fa-fw fa-envelope"></i>
                </div>
                <input
                  class="form-control"
                  placeholder="example@example.com"
                  type="email"
                  v-model="email"
                  disabled
                >
              </div>
              <!-- Password -->
              <div class="input-group mb-3">
                <div class="input-group-prepend input-group-text">
                  <i class="fa fa-fw fa-lock"></i>
                </div>
                <input
                  class="form-control"
                  placeholder="********"
                  type="password"
                  v-model="password"
                >
              </div>
              <!-- Номер телефона -->
              <div class="input-group mb-3">
                <div class="input-group-prepend input-group-text">
                  <i class="fa fa-fw fa-mobile"></i>
                </div>
                <input
                  class="form-control"
                  placeholder="+x (xxx) xxx-xx-xx"
                  type="text"
                  v-model="phone"
                >
              </div>
              <!-- Ссылка соц. сети -->
              <div class="input-group mb-3">
                <div class="input-group-prepend input-group-text">
                  <i class="fa fa-fw fa-link"></i>
                </div>
                <input
                  class="form-control"
                  placeholder="https://t.me/xxxxxxxxxx"
                  type="text"
                  v-model="telegram"
                >
              </div>
            </div>
            <div class="card-footer d-grid gap-2">
              <button
                class="btn btn-primary"
                @click="setProfileData()"
              >{{$t('profile.save')}}</button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import axios from '@/modules/Axios'
import qrcode from 'qrcode'

export default {
  data () {
    return {
      email: undefined,
      telegram: undefined,
      phone: undefined,
      password: undefined,
      listener: undefined,
      loading: false,
    }
  },
  methods: {
    /**
       * Получение информации о профиле пользователя
       *
       * @returns {Promise<*>}
       */
    async getProfileData () {
      try {
        const response = await axios.get('User/Profile.php')
        this.email = response.data.email
        this.telegram = response.data.telegram
        this.phone = response.data.phone
      } catch (error) {
        return this.notify({
          title: this.$t('links.profile'),
          type: 'error',
          message: error.response
            ? this.$t(error.response.data)
            : error.message
        })
      }
    },
    /**
       * Изменение профиля пользователя
       */
    async setProfileData () {
      try {
        await axios.post('User/Profile.php', {
          telegram: this.telegram,
          phone: this.phone,
          password: this.password
        })
        this.notify({
          title: this.$t('links.profile'),
          type: 'success',
          message: this.$t('profile.success_message')
        })
      } catch (error) {
        this.notify({
          title: this.$t('links.profile'),
          type: 'error',
          message: error.response
            ? this.$t(error.response.data)
            : error.message
        })
      }
      this.getProfileData()
    },
  },
  created () {
    this.getProfileData()
    this.listener = event => {
      if (event.key === 'Enter') this.set()
    }
    window.addEventListener('keydown', this.listener)
  },
  destroyed () {
    if (this.listener) {
      window.removeEventListener('keydown', this.listener)
      this.listener = undefined
    }
  },
  notifications: { notify: {} }
}
</script>
